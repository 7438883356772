import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Container,
  Flex,
  FlexProps,
  Heading,
  HeadingProps,
  Show,
  SimpleGrid,
  SimpleGridProps,
  useMediaQuery,
} from '@chakra-ui/react';
import { WpLinkType } from '../types/WpLinkType';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Pagination } from 'swiper';
import Arrow from './icons/arrow';

import 'swiper/css';
import 'swiper/css/pagination';

export type RoomPresentationType = {
  headline: string;
  text: string;
  button: WpLinkType;
  images: {
    altText: string;
    gatsbyImage: IGatsbyImageData;
  }[];
  initFlag: boolean;
};

type Props = {
  blockLeft: RoomPresentationType;
  blockRight: RoomPresentationType;
  initFlag: boolean;
};

const Room = (props: RoomPresentationType) => {
  const [isDesktop] = useMediaQuery('(min-width: 768px)');

  const headline = props.headline || null;
  const text = props.text || null;
  const button = props.button || null;
  const images = props.images || [];
  let linkType = 'internal';
  if (button && button.url.slice(0, 4) === 'http') linkType = 'external';
  if (props.initFlag) {
    return (
      <Box
        flexShrink={1}
        _first={{ paddingLeft: { md: '20px', lg: '30px' } }}
        _last={{ paddingRight: { md: '20px', lg: '30px' } }}
        maxWidth={{
          base: '100%',
          md: 'calc(50% - 20px)',
          lg: 'calc(50% - 30px)',
        }}
      >
        <Box {...sliderWrapperProps}>
          {images.length > 0 && (
            <Swiper
              modules={[Pagination]}
              pagination={isDesktop ? false : true}
              spaceBetween={24}
              loop={true}
            >
              {images.map((image, index) => (
                <SwiperSlide key={index}>
                  <Box {...slideProps}>
                    <Show above="md">
                      <GatsbyImage
                        alt={image.altText || ''}
                        image={image.gatsbyImage}
                      />
                    </Show>
                    <Show below="md">
                      <GatsbyImage
                        alt={image.altText || ''}
                        image={image.gatsbyImage}
                        style={{
                          height: 'calc(100vw - 2rem)',
                        }}
                      />
                    </Show>
                  </Box>
                </SwiperSlide>
              ))}

              <Show above="md">
                <Box {...navigationContainerProps}>
                  <SlidePrevButton />
                  <SlideNextButton />
                </Box>
              </Show>
            </Swiper>
          )}
        </Box>
        {headline && (
          <Heading as="h3" {...headlineProps}>
            {headline}
          </Heading>
        )}
        {text && (
          <SimpleGrid {...roomGridProps}>
            <Box {...textProps} dangerouslySetInnerHTML={{ __html: text }} />
          </SimpleGrid>
        )}
        {button && (
          <Box marginTop="24px">
            {linkType === 'internal' && (
              <Button
                as={Link}
                to={button.url}
                target={button.target}
                variant="whiteToOrange"
              >
                {button.title}
              </Button>
            )}
            {linkType === 'external' && (
              <Button
                as="a"
                href={button.url}
                target={button.target}
                variant="whiteToOrange"
              >
                {button.title}
              </Button>
            )}
          </Box>
        )}
      </Box>
    );
  }
  return <></>;
};

const SlideNextButton = () => {
  const swiper = useSwiper();

  return (
    <Button
      {...buttonNext}
      onClick={() => {
        swiper.slideNext();
      }}
    >
      <Arrow />
    </Button>
  );
};

const SlidePrevButton = () => {
  const swiper = useSwiper();

  return (
    <Button
      {...buttonPrev}
      onClick={() => {
        swiper.slidePrev();
      }}
    >
      <Arrow />
    </Button>
  );
};

const RoomPresentation = ({ blockLeft, blockRight, initFlag }: Props) => {
  return (
    <Box {...wrapperProps}>
      <Container>
        <Flex {...innerWrapperProps}>
          <Room {...blockLeft} initFlag={initFlag} />
          <Box height="80px" width="40px" flexShrink={0}></Box>
          <Room {...blockRight} initFlag={initFlag} />
        </Flex>
      </Container>
    </Box>
  );
};

const buttonPrev: ButtonProps = {
  position: 'absolute',
  left: '-6px',
  variant: 'slideshowNavigation',
  role: 'group',
  transform: 'rotate(180deg)',
};
const buttonNext: ButtonProps = {
  position: 'absolute',
  right: '-6px',
  variant: 'slideshowNavigation',
  role: 'group',
};
const navigationContainerProps: BoxProps = {
  position: 'absolute',
  zIndex: 50,
  marginTop: '-20px',
  top: '50%',
  left: { md: '2%', xl: '-10px' },
  width: { md: '96%', xl: 'calc(100% + 20px)' },
};

const sliderWrapperProps: BoxProps = {
  marginX: { base: '-1em' },
  paddingX: { md: '1em' },
  overflow: 'hidden',
  sx: {
    '.swiper': {
      width: '100%',
      overflow: 'visible',
      paddingBottom: { base: '60px', md: '16px' },
    },
    '.swiper-pagination': {
      marginX: '8px !important',
      bottom: '24px !important',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '.swiper-pagination-bullet': {
      background: 'white',
      width: '8px',
      height: '8px',
      opacity: 1,
      marginX: '4px !important',
    },
    '.swiper-pagination-bullet-active': {
      background: 'white',
      width: '12px',
      height: '12px',
    },
  },
};

const slideProps: BoxProps = {
  borderRadius: { base: '10px', md: '20px' },
  transform: 'translate3d(0,0,0)',
  overflow: 'hidden',
  marginX: { base: '1em', md: '0px' },
};

const headlineProps: HeadingProps = {
  color: 'white',
  fontSize: { base: '48px', md: '2xl' },
  lineHeight: { base: 'short', md: 'shorter' },
  marginY: '8px',
};

const textProps: BoxProps = {
  color: 'white',
  fontSize: { md: 'md' },
  gridColumn: { xl: 'span 5' },
};

const innerWrapperProps: FlexProps = {
  flexDirection: { base: 'column', md: 'row' },
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
};

const roomGridProps: SimpleGridProps = {
  columns: { xl: 6 },
  columnGap: '40px',
};

const wrapperProps: BoxProps = {
  background: 'black',
  paddingTop: { base: '100px', md: '120px' },
  paddingBottom: { base: '80px', md: '120px' },
};

export default RoomPresentation;
