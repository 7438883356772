import { Box, BoxProps, Container, Flex, Show } from '@chakra-ui/react';
import React from 'react';

export type PriceType = {
  title: string;
  description: string;
  price: string;
  priceExtension: string;
};

type Props = {
  prices: PriceType[];
};

const Prices = (props: Props) => {
  const prices = props.prices || [];
  return (
    <Container>
      {prices.map((price, index) => (
        <Box {...priceContainerProps} key={index}>
          <Show below="md">
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              marginBottom="10px"
            >
              <Box
                fontSize="md"
                fontWeight="700"
                // overflowWrap="break-word"
                wordBreak="break-word"
              >
                {price.title}
              </Box>
              <Flex flexDirection="row" alignItems="baseline">
                <Box as="span" marginX="6px" whiteSpace="nowrap">
                  {price.priceExtension}
                </Box>
                <Box
                  as="span"
                  fontSize="md"
                  fontWeight="700"
                  whiteSpace="nowrap"
                >
                  {price.price}
                </Box>
              </Flex>
            </Flex>
            <Box>{price.description}</Box>
          </Show>
          <Show above="md">
            <Flex flexDirection="row" justifyContent="space-between">
              <Box>
                <Box fontSize="lg" fontWeight="700">
                  {price.title}
                </Box>
                <Box fontSize="md">{price.description}</Box>
              </Box>
              <Box>
                <Box fontSize="lg" fontWeight="700" whiteSpace="nowrap">
                  {price.price}
                </Box>
                <Box fontSize="md" whiteSpace="nowrap">
                  {price.priceExtension}
                </Box>
              </Box>
            </Flex>
          </Show>
        </Box>
      ))}
    </Container>
  );
};

const priceContainerProps: BoxProps = {
  paddingY: '24px',
  _notLast: {
    borderBottom: '1px solid black',
  },
};

export default Prices;
