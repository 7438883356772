import React, { useLayoutEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import {
  Box,
  BoxProps,
  Button,
  Container,
  Heading,
  HeadingProps,
} from '@chakra-ui/react';
import Layout from '../components/layout';
import { WpLinkType } from '../types/WpLinkType';
import Seo from '../components/seo';
import HeroImage from '../components/heroImage';
import Prices, { PriceType } from '../components/prices';
import RoomPresentation, {
  RoomPresentationType,
} from '../components/roomPresentation';

type Props = {
  data: {
    pageData: {
      title: string;
      template: {
        pricingData: {
          heroImage: {
            gatsbyImage: IGatsbyImageData;
          };
          headline: string;
          text: string;
          prices: PriceType[];
          ctaButton: WpLinkType;
          roomPresentation: {
            blockLeft: RoomPresentationType;
            blockRight: RoomPresentationType;
          };
        };
      };
    };
    seoData: {
      seo: {
        metaDescription: string;
        metaKeywords: string;
      };
    };
  };
};

const Pricing = ({ data: { pageData, seoData } }: Props) => {
  const heroImage =
    pageData?.template?.pricingData?.heroImage?.gatsbyImage || null;
  const headline = pageData?.template?.pricingData?.headline || null;
  const text = pageData?.template?.pricingData?.text || '';
  const prices = pageData?.template?.pricingData?.prices || [];
  const button = pageData?.template?.pricingData?.ctaButton || null;
  const roomPresentation =
    pageData?.template?.pricingData?.roomPresentation || null;

  const [initFlag, setInitFlag] = useState(false);

  useLayoutEffect(() => {
    setInitFlag(true);
  }, []);

  return (
    <Layout>
      <Seo {...seoData.seo} title={pageData.title} />
      {heroImage && <HeroImage alt={pageData.title} image={heroImage} />}
      <Container
        marginTop={{ base: '100px', md: '120px' }}
        marginBottom={{ base: '80px', md: '100px' }}
      >
        {headline && <Heading {...headlineProps}>{headline}</Heading>}
        {text && (
          <Box {...textProps} dangerouslySetInnerHTML={{ __html: text }} />
        )}
      </Container>
      <Prices prices={prices} />
      {button && (
        <Container
          marginTop={{ base: '16px', md: '56px' }}
          marginBottom={{ base: '80px', md: '80px' }}
          textAlign="center"
        >
          <Button as={Link} to={button.url} variant="blackToOrange">
            {button.title}
          </Button>
        </Container>
      )}
      {roomPresentation && (
        <RoomPresentation {...roomPresentation} initFlag={initFlag} />
      )}
    </Layout>
  );
};

const headlineProps: HeadingProps = {
  fontSize: { base: '48px', md: '2xl' },
  lineHeight: 'shorter',
  marginBottom: { base: '24px', md: '10px' },
};
const textProps: BoxProps = {
  fontSize: { md: 'md' },
  paddingRight: { lg: '20px' },
  maxWidth: { lg: '50%' },
};

export default Pricing;

export const pageQuery = graphql`
  query PricingQuery($id: String!) {
    pageData: wpPage(id: { eq: $id }) {
      title
      template {
        ... on WpTemplate_Pricing {
          pricingData {
            heroImage {
              gatsbyImage(
                width: 1440
                height: 450
                layout: FULL_WIDTH
                fit: COVER
              )
            }
            headline
            text
            prices {
              ... on WpTemplate_Pricing_Pricingdata_Prices_Row {
                title
                description
                price
                priceExtension
              }
            }
            ctaButton {
              title
              url
              target
            }
            roomPresentation {
              blockLeft {
                headline
                text
                images {
                  gatsbyImage(width: 620, layout: FULL_WIDTH, aspectRatio: 1.5)
                }
                button {
                  title
                  url
                  target
                }
              }
              blockRight {
                headline
                text
                images {
                  gatsbyImage(width: 620, layout: FULL_WIDTH, aspectRatio: 1.5)
                }
                button {
                  title
                  url
                  target
                }
              }
            }
          }
        }
      }
    }

    seoData: wpPage(id: { eq: $id }) {
      seo {
        metaDescription
        metaKeywords
      }
    }
  }
`;
