import { Box } from '@chakra-ui/react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';

type Props = {
  alt?: string;
  image: IGatsbyImageData;
};

const HeroImage = (props: Props) => {
  return (
    <Box height={{ base: '40vh', md: 'auto' }} overflow="hidden">
      <GatsbyImage
        alt={props.alt || ''}
        image={props.image}
        style={{ height: '100%', objectFit: 'cover' }}
      />
    </Box>
  );
};

export default HeroImage;
